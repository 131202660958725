import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ListIcon from '@mui/icons-material/List';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LogoutIcon from '@mui/icons-material/Logout';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DifferenceIcon from '@mui/icons-material/Difference';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ExploreIcon from '@mui/icons-material/Explore';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import Badge from '@mui/material/Badge';
import { useGetUserData, useLogoutUser } from '@hooks/useGetUserData';
import { useGetMetrics } from '@hooks/useMetrics';
import trackUse from '@utils/trackUse';
import { getCurrentDealEventStrObj, logPostHogEvent } from '@utils/index';
import { holidayStrategyIdLinks } from '@utils/constants/strategyIds';
import listLinks from './listLinks';
import { PageType } from './pageUtils';

export type PagesType = PageType[];

export const useAppState = () => {
  const { mutate: logout } = useLogoutUser();
  const { data: user, refetch: getUserData } = useGetUserData();
  const { data: metrics } = useGetMetrics();
  const location = useLocation();
  const pathname = location?.pathname;

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [nestedListsOpen, setNestedListsOpen] = useState<string[]>([]);

  useEffect(() => {
    if (!timer && !!user?.isAdmin) {
      const id = setInterval(() => {
        getUserData();
      }, 30000); // every thirty seconds
      setTimer(id);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [user?.isAdmin, timer]); // Depend only on `user?.isAdmin` and `timer`

  const newSuggestionsNumber =
    (metrics || [])?.find((m) => m.name === 'Suggestions')?.data || 0;
  const newPromotionsNumber =
    (metrics || [])?.find((m) => m.name === 'Promotions')?.data || 0;

  const handleOpenNavDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseNavDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderBadge = (node: React.ReactNode, dataNum: number) => {
    if (dataNum && dataNum > 0) {
      return (
        <Badge badgeContent={dataNum} color="error" max={999}>
          {node}
        </Badge>
      );
    }
    return node;
  };

  const pages = useMemo(() => {
    const event = getCurrentDealEventStrObj();
    const basePages: PagesType = [
      {
        name: event?.navLinkTitle || '',
        link: event?.navLinkHref || '',
        isHidden: !event?.showNavLinkOption,
        isPrimeIcon: true,
        icon: <CelebrationIcon />,
        ringingIcon: !!event?.navLinkAnimated
      },
      {
        name: 'Blog',
        link: '/blog',
        icon: <NewspaperIcon />
      },
      {
        name: 'Variations',
        link: '/variations',
        icon: <DifferenceIcon />
      },
      {
        name: 'Email Alerts',
        link: '/email',
        icon: <LocalFireDepartmentIcon />
      }
    ];

    if (event?.showGiftGuideLinks) {
      basePages.push({
        name: 'Gift Guides',
        children: holidayStrategyIdLinks.map((link) => ({
          name: link.label,
          link: `${link.link}`,
          icon: link.icon
        })),
        icon: <CardGiftcardIcon />
      });
    }

    basePages.push({
      name: 'Top Deal Lists',
      icon: <ExploreIcon />,
      children: listLinks
    });

    if (user?.isAdmin) {
      basePages.push({
        name: 'Admin',
        children: [
          {
            name: 'Ideas',
            link: '/dealsIdeas',
            icon: renderBadge(
              <LightbulbIcon />,
              newSuggestionsNumber + newPromotionsNumber
            )
          },
          {
            name: 'Recommendations',
            link: '/mailing-list-recommendations',
            icon: <DynamicFeedIcon />
          },
          {
            name: 'Variations Searched',
            link: '/variations-searched',
            icon: <ScreenSearchDesktopIcon />
          },
          {
            name: 'Deal Lists Admin',
            link: '/deal-lists',
            icon: <ListIcon />
          },
          {
            name: 'Stats',
            link: '/stats',
            icon: <WaterfallChartIcon />
          }
        ],
        icon: renderBadge(
          <SupervisorAccountIcon />,
          newSuggestionsNumber + newPromotionsNumber
        )
      });
    }

    if (!user) {
      basePages.push({
        name: 'Login',
        link: '/login',
        icon: <AccountCircleIcon />,
        showDividerBefore: true
      });

      basePages.push({
        name: 'Register',
        link: '/signup',
        icon: <PersonAddAlt1Icon />,
        showDividerAfter: true
      });
    } else {
      basePages.push({
        name: 'Account',
        link: '/account',
        icon: <AccountCircleIcon />,
        showDividerBefore: true,
        showDividerAfter: true
      });
    }

    return basePages;
  }, [user, metrics]);

  const settings = useMemo(
    () => [
      {
        name: 'Account',
        link: '/account',
        isShown: true,
        icon: <AccountCircleIcon />
      },
      {
        name: 'Submit Deal',
        link: '/submit-deal',
        isShown: !!user,
        icon: <AddLinkIcon />
      },
      {
        name: 'Variations',
        link: '/variations-searched',
        isShown: !!user?.isAdmin,
        icon: <ScreenSearchDesktopIcon />
      },
      {
        name: 'Deal Lists Admin',
        link: '/deal-lists',
        isShown: !!user?.isAdmin,
        icon: <ListIcon />
      },
      {
        name: 'Ideas',
        link: '/dealsIdeas',
        isShown: !!user?.isAdmin,
        icon: renderBadge(
          <LightbulbIcon />,
          newSuggestionsNumber + newPromotionsNumber
        )
      },
      {
        name: 'Stats',
        link: '/stats',
        isShown: !!user?.isAdmin,
        icon: <WaterfallChartIcon />
      },
      {
        name: 'Recommendations',
        link: '/mailing-list-recommendations',
        isShown: !!user?.isAdmin,
        icon: <DynamicFeedIcon />
      },
      {
        name: 'Users',
        link: '/users',
        isShown: !!user?.isAdmin,
        icon: <AccountCircleIcon />
      },
      {
        name: 'Logout',
        onClick: () => {
          trackUse({
            item: 'menu-logout',
            value: `logout`,
            type: 'CLICK'
          });

          logPostHogEvent('menu-logout', {
            value: `logout`,
            type: 'CLICK'
          });

          logout();
        },
        isShown: true,
        icon: <LogoutIcon />
      }
    ],
    [user, newSuggestionsNumber, newPromotionsNumber, logout]
  );

  const loggedOutOptions = useMemo(() => {
    return [
      {
        name: 'Login',
        link: '/login',
        icon: <AccountCircleIcon />
      },
      {
        name: 'Register',
        link: '/signup',
        icon: <PersonAddAlt1Icon />
      }
    ];
  }, []);

  return {
    anchorElUser,
    setAnchorElUser,
    timer,
    setTimer,
    isDrawerOpen,
    setIsDrawerOpen,
    metrics,
    pathname,
    pages,
    settings,
    loggedOutOptions,
    user,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleOpenNavDrawer,
    handleCloseNavDrawer,
    renderBadge,
    nestedListsOpen,
    setNestedListsOpen
  };
};
