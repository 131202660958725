import React, { useMemo, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Global } from '@emotion/react';

import { useTheme } from '@mui/system';
import config from '@configFile';
import { useGetMetrics } from '@hooks/useMetrics';
import useServiceWorkerUpdate from '@hooks/useServiceWorkerUpdate';
import FeedbackButton from '@components/FeedbackButton';
import Footer from '@components/Footer';
import CountdownBanner from '@components/PageContainer/CountdownBanner';
import colors from '@utils/colors';
import EmailLists from '@components/EmailLists';
import SearchBox from '@components/SearchBox';
import MenuButton from '@components/MenuButton';
import { getCurrentDealEventStrObj } from '@utils/eventUtils';
import { holidayStrategyIdLinks } from '@utils/constants/strategyIds';
import classes from './styles';
import HideOnScroll from './components/HideOnScroll';
import Sockets from './sockets';
import MobileMenu from './MobileMenu';
import { appName } from './utils';
import globalStyles from './globalStyles';
import SkipToContent from './components/SkipToContent';
import SideMenu from './components/SideMenu';
import ToolbarSearch from './components/ToolbarSearch';
import socialLinks from './socialLinks';
import adminLinks from './adminLinks';
import listLinks from './listLinks';
import {
  usePageOnClick,
  PageType,
  lightIconStyles,
  logoStyle,
  emailIconContainerStyle,
  mainStyles
} from './pageUtils';
import { useAppState } from './useAppState';
import DesktopMenuButtons from './components/DesktopMenuButtons';

interface Route {
  route: { routes: Array<object> };
}

const linkHeader = 'Lists';
const linkList = listLinks;

const App = ({ route }: Route) => {
  useServiceWorkerUpdate();
  const theme = useTheme();
  const {
    anchorElUser,
    isDrawerOpen,
    pages,
    settings,
    loggedOutOptions,
    user,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleOpenNavDrawer,
    handleCloseNavDrawer,
    renderBadge,
    nestedListsOpen,
    setNestedListsOpen
  } = useAppState();
  const currentEvent = getCurrentDealEventStrObj();
  const history = useHistory();
  const [searchOpen, setSearchOpen] = useState(false);
  const { data: metrics } = useGetMetrics();
  const pageOnClickHandle = usePageOnClick();
  const location = useLocation();
  const pathname = location?.pathname;
  const isDarkMode = theme.palette.mode === 'dark';

  const newSuggestionsNumber = useMemo(
    () => (metrics || [])?.find((m) => m.name === 'Suggestions')?.data || 0,
    [metrics]
  );
  const newPromotionsNumber = useMemo(
    () => (metrics || [])?.find((m) => m.name === 'Promotions')?.data || 0,
    [metrics]
  );

  const pageOnClick = (page: PageType): void => {
    pageOnClickHandle(page);
    handleCloseNavDrawer();
  };

  const renderMenuButtons = () => {
    const buttonStyles = {
      backgroundColor: colors.jdbPurple,
      padding: '6px 16px',
      ':hover': {
        backgroundColor: '#272038'
      }
    };
    return (
      <Box
        display={{
          xs: 'none',
          lg: 'block'
        }}
      >
        {currentEvent?.showGiftGuideLinks ? (
          <MenuButton
            linkList={holidayStrategyIdLinks}
            linkHeader="Gift Guides"
            pathname={pathname}
            onClick={pageOnClick}
          />
        ) : null}
        <Button
          color="inherit"
          href="/blog"
          onClick={() => {
            pageOnClick({ name: 'Blog', link: '/blog' });
          }}
          sx={buttonStyles}
        >
          Blog
        </Button>
        <MenuButton
          linkList={linkList}
          linkHeader={linkHeader}
          pathname={pathname}
          onClick={pageOnClick}
        />
        <Button
          color="inherit"
          onClick={() => {
            pageOnClick({
              name: 'Variations',
              link: '/variations'
            });
          }}
          sx={buttonStyles}
        >
          Variations
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            pageOnClick({
              name: 'Email Alerts',
              link: '/email'
            });
          }}
          sx={buttonStyles}
        >
          Email Alerts
        </Button>
        <MenuButton
          linkList={socialLinks}
          linkHeader="Social"
          pathname={pathname}
          onClick={pageOnClick}
        />
        {user?.isAdmin ? (
          <MenuButton
            linkList={[
              {
                name: 'Ideas',
                link: '/dealsIdeas',
                icon: renderBadge(
                  <LightbulbIcon />,
                  newSuggestionsNumber + newPromotionsNumber
                ) as React.ReactNode
              },
              ...adminLinks
            ]}
            linkHeader="Admin"
            pathname={pathname}
            onClick={pageOnClick}
          />
        ) : (
          <FeedbackButton showButton />
        )}
      </Box>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Box sx={{ flexGrow: 1, display: { xs: 'none' } }}>
        {pages.map((page) => (
          <Button
            key={page.name}
            onClick={() => {
              history.push(page.link);
              handleCloseUserMenu();
            }}
            sx={{ my: 2, color: 'white', display: 'block' }}
          >
            {page.name}
          </Button>
        ))}
      </Box>
    );
  };

  const renderMenuAppBar = () => {
    return (
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => {
          if (!setting.isShown) {
            return null;
          }
          return (
            <MenuItem
              key={setting.name}
              onClick={() => {
                if (setting.onClick) {
                  setting.onClick();
                }

                if (setting.link && setting.link !== location.pathname) {
                  history.push(setting.link);
                }

                handleCloseUserMenu();
              }}
            >
              {' '}
              {setting.icon && <ListItemIcon>{setting.icon}</ListItemIcon>}
              {setting.icon ? (
                <ListItemText>{setting.name}</ListItemText>
              ) : (
                <Typography textAlign="center">{setting.name}</Typography>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderAccountMenu = () => {
    return (
      <Box sx={{ flexGrow: 0, marginRight: '12px' }}>
        <Tooltip title="Account menu">
          <IconButton
            aria-label="Account menu"
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
          >
            {renderBadge(
              <Avatar
                alt={`${user?.firstName} ${user?.lastName}`}
                sx={
                  isDarkMode
                    ? {
                        color: '#fff',
                        backgroundColor: '595959'
                      }
                    : undefined
                }
              >
                {user.firstName?.charAt(0)?.toUpperCase() || ''}
              </Avatar>,
              newSuggestionsNumber + newPromotionsNumber
            )}
          </IconButton>
        </Tooltip>
        {renderMenuAppBar()}
      </Box>
    );
  };

  const renderLoggedOutMenu = () => {
    return (
      <Box sx={{ flexGrow: 0, marginRight: '12px' }}>
        <IconButton
          aria-label="Login menu"
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
        >
          <Avatar
            alt="Login"
            sx={
              isDarkMode
                ? {
                    color: '#fff',
                    backgroundColor: '595959'
                  }
                : undefined
            }
          >
            <PersonIcon />
          </Avatar>
        </IconButton>

        <Menu
          sx={{ mt: '45px' }}
          id="logged-out-menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {loggedOutOptions.map((setting) => {
            return (
              <MenuItem
                key={setting.name}
                onClick={() => {
                  if (setting.link) {
                    history.push(setting.link);
                  }

                  handleCloseUserMenu();
                }}
              >
                {' '}
                {setting.icon && <ListItemIcon>{setting.icon}</ListItemIcon>}
                {setting.icon ? (
                  <ListItemText>{setting.name}</ListItemText>
                ) : (
                  <Typography textAlign="center">{setting.name}</Typography>
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  };

  const renderToolbarContent = () => {
    if (searchOpen) {
      return <ToolbarSearch setSearchOpen={setSearchOpen} />;
    }

    return (
      <>
        <SideMenu
          isDrawerOpen={isDrawerOpen}
          handleCloseNavDrawer={handleCloseNavDrawer}
          pages={pages}
          handleOpenNavDrawer={handleOpenNavDrawer}
          nestedListsOpen={nestedListsOpen}
          setNestedListsOpen={setNestedListsOpen}
        />
        <Box
          sx={{
            display: { xs: 'flex' },
            position: 'absolute',
            textAlign: 'left',
            // marginLeft: `12px`,
            marginLeft: {
              xl: '12px',
              lg: '12px',
              md: '60px',
              sm: '60px',
              xs: '60px'
            }
            // '@media (max-width: 1199px)': {
            //   marginLeft: '60px'
            // }
          }}
        >
          <Typography
            variant="h5"
            noWrap
            component="a"
            href={`${config.API_PREFIX}/`}
            sx={logoStyle}
          >
            {appName}
          </Typography>
          {renderMenuButtons()}
        </Box>
        {renderMobileMenu()}
        <Box
          sx={{
            '@media (max-width: 700px)': {
              display: 'none'
            }
          }}
        >
          <SearchBox />
        </Box>
        <Box
          display="none"
          sx={{
            '@media (max-width: 700px)': {
              display: 'block'
            }
          }}
        >
          <IconButton
            onClick={() => {
              setSearchOpen(true);
            }}
            aria-label="Search all deals"
          >
            <SearchIcon sx={lightIconStyles} />
          </IconButton>
        </Box>
        {!!user?.hasVerifiedEmail && (
          <Box sx={emailIconContainerStyle}>
            <EmailLists />
          </Box>
        )}
        {user ? renderAccountMenu() : renderLoggedOutMenu()}
      </>
    );
  };

  return (
    <Box
      sx={{
        ...classes.App,
        color: theme.palette.text.primary
      }}
      id="jdb-app-container"
    >
      <SkipToContent />
      <Global styles={globalStyles} />
      <Box component="main" data-testid="main" sx={mainStyles}>
        <Box data-testid="outer-helmet" sx={classes.outerHelmet} padding="0">
          <Helmet {...config.app} />
          <Box
            sx={{
              backgroundColor: colors.jdbPurple
            }}
            data-testid="inner-helmet"
          >
            <HideOnScroll>
              <AppBar
                sx={{
                  backgroundColor: colors.jdbPurple,
                  backgroundImage: 'none',
                  maxWidth: '100%',
                  width: '100%'
                }}
              >
                <Box
                  maxWidth="xl"
                  sx={{
                    maxWidth: '100% !important',
                    // padding: '0px 24px 0 12px'
                    padding: {
                      xs: '0px',
                      sm: '0px',
                      md: '0px',
                      lg: '0px',
                      xl: '0px'
                    }
                  }}
                >
                  <Toolbar
                    disableGutters
                    sx={{
                      minHeight: '65px !important',
                      maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '1360px',
                        xl: '1360px'
                      },
                      margin: '0 auto'
                    }}
                  >
                    {renderToolbarContent()}
                  </Toolbar>
                  <DesktopMenuButtons />
                </Box>
              </AppBar>
            </HideOnScroll>
            <Box
              sx={{
                // marginTop: '65px'
                // 65px + 30px = 94px
                marginTop: {
                  xs: '65px',
                  sm: '65px',
                  md: '95px',
                  lg: '95px',
                  xl: '95px'
                }
              }}
            />
            <MobileMenu />
          </Box>
          <CountdownBanner />
          {/* Child routes won't render without this */}
          {renderRoutes(route.routes)}
        </Box>
        <Sockets />
      </Box>
      <Footer />
    </Box>
  );
};

export default App;
