import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MUILink } from '@mui/material';
import Box from '@mui/material/Box';
import colors from '@utils/colors';

const linkStyles = {
  color: '#fff',
  textDecoration: 'none',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontSize: {
    xs: '13px',
    sm: '14px'
  },
  padding: '6px 0'
};

const outerBoxStyles = {
  display: { xs: 'flex', sm: 'flex', md: 'none' },
  backgroundColor: colors.jdbPurple,
  justifyContent: 'space-around'
};

const MobileMenu = () => {
  return (
    <Box sx={outerBoxStyles}>
      <MUILink component={Link} to="/blog" sx={linkStyles}>
        Blog
      </MUILink>
      <MUILink component={Link} to="/variations" sx={linkStyles}>
        Variations
      </MUILink>
      <MUILink
        component={Link}
        to="/best-amazon-subscribe-save-deals"
        sx={linkStyles}
      >
        Subscribe & Save
      </MUILink>
      <MUILink component={Link} to="/email" sx={linkStyles}>
        <Box
          component="span"
          sx={{
            marginRight: '4px'
          }}
        >
          🔥
        </Box>{' '}
        Deal Alerts
      </MUILink>
    </Box>
  );
};

export default MobileMenu;
